import React from "react";
import "./_index.scss";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

function TextError(props) {
  return (
    <div className="error">
      <ReportProblemIcon />
      {props.children}
    </div>
  );
}

export default TextError;
