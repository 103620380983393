import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import "./_index.scss";

function Textarea(props) {
  const { disabled, label, name, className, ...rest } = props;
  return (
    <div className={`form-control ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <>
        <Field disabled={disabled} as="textarea" id={name} name={name} {...rest} />
        <ErrorMessage component={TextError} name={name} />
      </>
    </div>
  );
}

export default Textarea;
